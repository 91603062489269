import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// ############ INIT FIRESTORE DB
const firestoreCreds = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
};
if (!firebase.apps.length) {
    firebase.initializeApp(firestoreCreds);
}

const provider = new firebase.auth.GoogleAuthProvider();

// show account selection screen even if user is already logged into Google
// with a single account
provider.setCustomParameters({
    prompt: 'select_account',
});

export const db = firebase.firestore();
export const auth = firebase.auth();

export const signInWithGoogle = () => {
    auth.signInWithPopup(provider);
};

export const signOut = () => {
    auth.signOut();
};
