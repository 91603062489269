const ModalService = {
    on(event, callback) {
        document.addEventListener(event, (e) => callback(e.detail));
    },
    open(component, { title, body }) {
        document.dispatchEvent(new CustomEvent('open', { detail: { component, title, body } }));
    },
};

export default ModalService;
