import PropTypes from 'prop-types';
import { GiGluttonousSmile } from 'react-icons/gi';
import { FcGoogle } from 'react-icons/fc';
import { IconContext } from 'react-icons';

const SignIn = ({ onClick }) => (
    <div className="d-flex align-items-center vh-100">
        <div className="container sign-in p-5 col-9 shadow mb-5 bg-white rounded">
            <IconContext.Provider value={{ style: { verticalAlign: 'sub' } }}>
                <h2>
                    Hello
                    {' '}
                    <GiGluttonousSmile />
                </h2>
                <hr className="mb-4" />
                <p className="text-muted">use your Vinhood account to sign in</p>
                <div className="d-flex my-5 justify-content-center">
                    <button
                        className="d-flex btn btn-outline-primary"
                        type="button"
                        onClick={onClick}
                    >
                        <div className="d-flex align-self-stretch align-items-center">
                            <FcGoogle />
                        </div>
                        <div className="pb-05 google-btn">
                            &nbsp;
                            Sign in with Google
                        </div>
                    </button>
                </div>
            </IconContext.Provider>
        </div>
    </div>
);

SignIn.defaultProps = {
    onClick: () => {},
};

SignIn.propTypes = {
    onClick: PropTypes.func,
};

export default SignIn;
