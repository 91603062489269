/* eslint-disable react/prop-types */
import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Table from './Table';
import LoadingOverlay from './LoadingOverlay';
import RTLswitch from './RTLswitch';

import { listenRTLData, toggleRTL } from '../utils';
import { tasteAdvisor, vinhood } from '../const';

const TableContainer = ({ db }) => {
    const [VHData, setVHData] = useState([]);
    const [TAData, setTAData] = useState([]);
    const [data, setData] = useState([]);
    const [env, setEnv] = useState('');
    const location = useLocation();

    const getRowProps = (row) => {
        const daysInAlert = row.values.days_in_alert_state;
        const isToggled = row.values.is_toggled;

        return {
            className: `
                ${daysInAlert > 0 ? 'table-warning' : ''}
                ${isToggled === false ? 'inactive' : ''}
            `,
        };
    };

    const setRTLData = (dataStream, environment) => {
        switch (environment) {
        case tasteAdvisor:
            setTAData(dataStream);
            break;

        case vinhood:
            setVHData(dataStream);
            break;

        default:
            break;
        }
    };

    useEffect(() => {
        const unsubscribeTA = listenRTLData(db, tasteAdvisor, setRTLData);
        const unsubscribeVH = listenRTLData(db, vinhood, setRTLData);

        return () => {
            unsubscribeTA();
            unsubscribeVH();
        };
    }, [db]);

    useEffect(() => {
        if (location.pathname === '/taste-advisor') {
            setData(TAData);
            setEnv(tasteAdvisor);
        } else if (location.pathname === '/vinhood') {
            setData(VHData);
            setEnv(vinhood);
        }
    }, [location, TAData, VHData]);

    const columns = useMemo(() => [
        {
            Header: '',
            id: 'is_toggled',
            accessor: (row) => (row.is_toggled === undefined ? true : row.is_toggled),
            Cell: ({ row: { values } }) => (
                <RTLswitch db={db} data={values} callback={toggleRTL} env={env} />
            ),
            sortType: 'basic',
        },
        {
            Header: 'ID',
            accessor: 'id',
            disableSortBy: true,
        },
        {
            Header: 'Name',
            accessor: 'description',
            disableSortBy: true,
        },
        {
            Header: 'Website',
            accessor: 'web_address',
            disableSortBy: true,
        },
        {
            Header: 'Daily avg. above 3',
            accessor: 'mean_above_three',
            Cell: ({ value }) => (value === true ? 'Yes' : 'No'),
            disableSortBy: true,
        },
        {
            Header: 'Active last 3 days',
            accessor: 'is_active_last_three_days',
            Cell: ({ value }) => (value === true ? 'Yes' : 'No'),
            disableSortBy: true,
        },
        {
            Header: 'Active last day',
            accessor: 'is_active_last_day',
            Cell: ({ value }) => (value === true ? 'Yes' : 'No'),
            disableSortBy: true,
        },
        {
            Header: () => (<span className="text-end">Days in alarm</span>),
            accessor: 'days_in_alert_state',
            Cell: ({ value }) => (<span className="text-end">{value}</span>),
        },
    ], [db, env]);

    const sortBy = useMemo(() => [
        { id: 'is_toggled', desc: true },
        { id: 'days_in_alert_state', desc: true },
    ], []);

    return (
        <>
            {
                data.length > 0
                    ? (
                        <Table
                            columns={columns}
                            data={data}
                            sortBy={sortBy}
                            getRowProps={getRowProps}
                        />
                    )
                    : <LoadingOverlay />
            }
        </>
    );
};

TableContainer.defaultProps = {
    db: {},
};

TableContainer.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    db: PropTypes.object,
};

export default TableContainer;
