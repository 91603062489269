/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';

const Table = ({ columns, data, sortBy, getRowProps }) => {
    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { sortBy },
        },
        useSortBy,
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    useEffect(() => {
        const myTable = document.getElementsByClassName('table-responsive-lg')[0];
        myTable.scrollTo(0, 0);
    }, [data]);

    return (
        <div className="row">
            <div className="col-lg">
                <div className="table-responsive-lg table-wrapper">
                    <table className="table align-middle table-hover small" {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            className="px-3"
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps(),
                                            )}
                                        >
                                            {column.render('Header')}
                                            <span>
                                                {/* eslint-disable-next-line no-nested-ternary */}
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? (
                                                            <>
                                                                {' '}
                                                                <TiArrowSortedDown />
                                                            </>
                                                        )
                                                        : (
                                                            <>
                                                                {' '}
                                                                <TiArrowSortedUp />
                                                            </>
                                                        )
                                                    : ''}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                rows.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps(getRowProps(row))}>
                                            {
                                                row.cells.map((cell) => (
                                                    <td className="px-3 py-3" {...cell.getCellProps()}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

Table.defaultProps = {
    columns: [],
    data: [],
    sortBy: [],
    getRowProps: () => ({}),
};

Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.object),
    sortBy: PropTypes.arrayOf(PropTypes.object),
    getRowProps: PropTypes.func,
};

export default Table;
