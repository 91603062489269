import Modal from '../components/modals/Modal';
import ModalService from '../components/modals/ModalService';
import { signOut } from './firebase';

const wrongAccontMessage = (
    <small>
        You tried to login with an email address outside the Vinhood organization.
        <br />
        <br />
        Please log into the board with a Vinhood account.
    </small>
);

const addModal = () => {
    ModalService.open(
        Modal,
        {
            title: 'Oh noes!',
            body: wrongAccontMessage,
        },
    );
};

export const listenRTLData = (db, env, callback) => {
    const collection = db.collection(env);

    const unsubscribe = collection.onSnapshot(
        (querySnapshot) => {
            const dataStream = querySnapshot

                .docs
                .map((doc) => ({ ...doc.data(), id: doc.id }));

            callback(dataStream, env);
        },
        (err) => {
            addModal();
            signOut();
            return err;
        },
    );

    return unsubscribe;
};

export const toggleRTL = (db, env, id, isToggled) => {
    const ref = db.collection(env).doc(id);
    ref.set({
        is_toggled: !isToggled,
    }, { merge: true });
};
