import 'react-toggle/style.css';

import PropTypes from 'prop-types';
import Toggle from 'react-toggle';

const RTLswitch = ({ db, env, data, callback }) => {
    const { id, is_toggled: isToggled } = data;
    return (
        <Toggle
            checked={isToggled}
            icons={false}
            // onChange needs to programmatically trigger onBlur
            // which in turn triggers the callback that updates
            // the value in the database.
            // This is needed to prevent Firefox from scrolling the table
            // down and up when coupled with bootstrap v5 and auto-sort
            // of react-table
            onChange={(e) => e.target.blur()}
            onBlur={() => callback(db, env, id, isToggled)}
            className="align-middle toggle-custom-styles"
        />
    );
};

RTLswitch.defaultProps = {
    db: {},
    env: '',
    data: {
        id: '',
        is_toggled: true,
    },
    callback: () => {},
};

RTLswitch.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    db: PropTypes.object,
    env: PropTypes.string,
    data: PropTypes.shape({
        id: PropTypes.string,
        is_toggled: PropTypes.bool,
    }),
    callback: PropTypes.func,
};

export default RTLswitch;
