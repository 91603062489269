import PropTypes from 'prop-types';

const Modal = ({ title, body, close }) => (
    <div className="modal d-block" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header bg-warning">
                    <h5 className="modal-title fw-bold" id="modalBasicLabel">{title}</h5>
                    <button onClick={close} type="button" className="btn-close" aria-label="Close" />
                </div>
                <div className="modal-body">
                    {body}
                </div>
                <div className="modal-footer">
                    <button onClick={close} type="button" className="btn btn-secondary">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
);

Modal.defaultProps = {
    title: '',
    body: {},
    close: () => {},
};

Modal.propTypes = {
    title: PropTypes.string,
    body: PropTypes.element,
    close: PropTypes.func,
};

export default Modal;
