import { useState, useEffect } from 'react';
import ModalService from './ModalService';

const ModalRoot = () => {
    const [modal, setModal] = useState({});

    const manageModal = ({ component, title, body }) => {
        setModal({
            component,
            title,
            body,
            close() {
                setModal({});
            },
        });
    };

    useEffect(() => {
        ModalService.on('open', manageModal);
    }, []);

    const ModalComponent = modal.component ? modal.component : null;

    return (
        <section className={modal.component ? 'modal-root' : ''}>
            {modal.component && (
                <ModalComponent
                    title={modal.title}
                    body={modal.body}
                    close={modal.close}
                    className={ModalComponent ? 'd-block' : ''}
                />
            )}
        </section>
    );
};

export default ModalRoot;
