const LoadingOverlay = () => (
    <>
        {/* <div className="dark-background" /> */}
        <div className="text-center spinner-container">
            <div className="spinner-border m-3 text-primary" role="status" />
            <div>Loading...</div>
        </div>
    </>
);

export default LoadingOverlay;
