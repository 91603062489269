import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const Navbar = ({ title, signOut }) => (
    <nav className="navbar sticky-top navbar-expand-md navbar-dark bg-dark mb-3">
        <div className="container px-5">
            <div className="navbar-brand" to="/">{title}</div>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsingNavbar">
                <span className="navbar-toggler-icon" />
            </button>
            <div className="navbar-collapse collapse justify-content-end" id="collapsingNavbar">
                <div className="navbar-nav me-5">
                    <NavLink className="nav-link" activeClassName="active" to="/vinhood">Vinhood</NavLink>
                    <NavLink className="nav-link" activeClassName="active" to="/taste-advisor">Taste Advisor</NavLink>
                </div>
                <button className="btn btn-sm btn-warning" type="button" onClick={signOut}>
                    Sign out
                </button>
            </div>
        </div>
    </nav>
);

Navbar.defaultProps = {
    title: '',
    signOut: () => {},
};

Navbar.propTypes = {
    title: PropTypes.string,
    signOut: PropTypes.func,
};

export default Navbar;
