import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';

import { db, auth, signInWithGoogle, signOut } from './utils/firebase';
import SignIn from './components/SignIn';
import Navbar from './components/Navbar';
import TableContainer from './components/TableContainer';
import ModalRoot from './components/modals/ModalRoot';

function App() {
    const [isLoggedIn, updateAuthState] = useState(!!auth.currentUser);
    const [isAppLoading, updateLoadingState] = useState(true);

    useEffect(() => {
        // Authentication listener
        auth.onAuthStateChanged(
            (user) => {
                updateAuthState(!!user);
                updateLoadingState(false);
            },
        );
    }, [isLoggedIn, isAppLoading]);

    return (
        <Router>
            <ModalRoot />
            {!isAppLoading && !isLoggedIn && (
                <>
                    <Redirect to="/" />
                    <SignIn onClick={signInWithGoogle} />
                </>
            )}
            {!isAppLoading && isLoggedIn && (
                <div className="vh-100">
                    <Navbar title="Retailer location status" signOut={signOut} />
                    <Redirect to="/vinhood" />
                    <div className="container p-5">
                        <TableContainer db={db} />
                    </div>
                </div>
            )}
        </Router>
    );
}

export default App;
